import React from 'react'
import errorlogo from '../../../assets/images/common/zync_logo.png'


const ErrorPage=() => {

  

    return (
        <section className='errorPage'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='img-wrapper'>
                            <img src={errorlogo} className='img-fluid' alt='error logo' />
                        </div>
                        <div className='content'>
                            <h6>Oops, This page is unavailable.</h6>
                            <p>The page you are looking for isn’t available.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ErrorPage;
