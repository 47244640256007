import React from 'react'
import noData from '../../assets/images/loader.gif'

const PreLoader = () => {
    return (
        <>
            <div className=' loader-wrapper'>

                <img src={noData} alt='no data' className='img-fluid' />

            </div>
        </>
    )
}

export default PreLoader