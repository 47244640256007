import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PreLoader from './components/common/preLoader';
import React, { Suspense } from 'react';

import './assets/css/style.scss'
import { Toaster } from 'react-hot-toast';
import { RecoilRoot } from 'recoil';
import 'ckeditor5/ckeditor5.css';
import ErrorPage from './pages/admin/errorPage/page-not-found';

const Login = React.lazy(() => import('./components/common/authentication/login'));
const ForgotPassword = React.lazy(() => import('./components/common/authentication/forgotPassword'));
const SetPassword = React.lazy(() => import('./components/common/authentication/setPassword'));
const AdminPanel = React.lazy(() => import('./pages/admin/adminPanel'));
const CandidateForm = React.lazy(() => import('./pages/admin/candidateManagement/CandidateForm'));
const DocumentVerification = React.lazy(() => import('./pages/admin/candidateManagement/documentVerification'));
const EntryConfirm = React.lazy(() => import('./pages/admin/candidateManagement/entryConfirm'));
const UploadDocuments = React.lazy(() => import('./pages/admin/candidateManagement/uploadDocuments'));
const DocumentSigning = React.lazy(() => import('./pages/admin/docSigningManagement/documentSigning'));
const DocumentView = React.lazy(() => import('./pages/admin/docSigningManagement/documentView'));


function App() {







  return (

    <BrowserRouter>
      <Toaster position="top-center" />
      <Suspense fallback={<PreLoader />}>
        <RecoilRoot>
          <Routes>

            {window.location.origin == 'https://zync.zaparetech.com' ?
              <>
                <Route exact path='/document-verification/:id/:token' element={<DocumentVerification />} />
                <Route path='/*' element={<ErrorPage />} />
                <Route exact path='/candidate-form/:eventId' element={<CandidateForm />} />
                <Route exact path='/entry-confirm' element={<EntryConfirm />} />
                <Route exact path='/upload-documents' element={<UploadDocuments />} />
                {/* Document Signing from mail click */}
                <Route exact path='/document-signing/:id' element={<DocumentSigning />} />
                <Route exact path='/document-view/:id' element={<DocumentView />} />
                {/* Document Signing from mail click */}
              </>
              :
              <>                
                <Route exact path='/upload-documents' element={<UploadDocuments />} />
                <Route exact path='/entry-confirm' element={<EntryConfirm />} />
                <Route path='/*' element={<AdminPanel />} />
                <Route exact path='/document-verification/:id/:token' element={<DocumentVerification />} />
                <Route exact path='/candidate-form/:eventId' element={<CandidateForm />} />

                <Route exact path='/login' element={<Login />} />
                <Route exact path='/forgot-password' element={<ForgotPassword />} />
                <Route exact path='/set-password' element={<SetPassword />} />    
              
              </>
            }





          </Routes>
        </RecoilRoot>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
